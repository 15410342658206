import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './common/AppStoreModule'
import appConfig from './common/AppConfigStoreModule'
import verticalMenu from './common/VerticalMenuStoreModule'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
})
