import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: 'https://gdc2.geziradentalclinic.com:8000/',
  // baseURL: 'http://geziraclinicbe02-001-site1.itempurl.com/',
  // baseURL: 'http://localhost:8081/',
  timeout: 1000000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
