export default [
  // *==================================================================*
  // *---------------------------- Doctors  ----------------------------*
  // *==================================================================*
  {
    path: '/main/doctor/list',
    name: 'doctor-list',
    component: () => import('@/views/main/doctor/doctor-list/DoctorList.vue'),
    meta: {
      pageTitle: 'Doctors',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Doctors',
          to: '/main/doctor/list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/main/doctor/view/:id',
    name: 'doctor-view',
    component: () => import('@/views/main/doctor/doctor-view/DoctorView.vue'),
    meta: {
      pageTitle: 'View Doctor',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Doctors',
          to: '/main/doctor/list',
        },
        {
          text: 'View Doctor',
          active: true,
        },
      ],
    },
  },
  {
    path: '/main/doctor/edit/:id',
    name: 'doctor-edit',
    component: () => import('@/views/main/doctor/doctor-edit/DoctorEdit.vue'),
    meta: {
      pageTitle: 'Edit Doctor',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Doctors',
          to: '/main/doctor/list',
        },
        {
          text: 'Edit Doctor',
          active: true,
        },
      ],
    },
  },
  // *==================================================================*
  // *---------------------------- Patients  ----------------------------*
  // *==================================================================*
  {
    path: '/main/patient/list',
    name: 'patient-list',
    component: () => import('@/views/main/patient/patient-list/PatientList.vue'),
    meta: {
      pageTitle: 'Patients',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Patients',
          to: '/main/patient/list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/main/patient/view/:id',
    name: 'patient-view',
    component: () => import('@/views/main/patient/patient-view/PatientView.vue'),
    meta: {
      pageTitle: 'View Patient',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Patients',
          to: '/main/patient/list',
        },
        {
          text: 'View Patient',
          active: true,
        },
      ],
    },
  },
  {
    path: '/main/patient/edit/:id',
    name: 'patient-edit',
    component: () => import('@/views/main/patient/patient-edit/PatientEdit.vue'),
    meta: {
      pageTitle: 'Edit Patient',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Patients',
          to: '/main/patient/list',
        },
        {
          text: 'Edit Patient',
          active: true,
        },
      ],
    },
  },
  // *==================================================================*
  // *---------------------------- Visit  ------------------------*
  // *==================================================================*
  {
    path: '/main/visit/list',
    name: 'visit-list',
    component: () => import('@/views/main/visit/visit-list/VisitList.vue'),
    meta: {
      pageTitle: 'Visits',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Visits',
          to: '/main/visit/list',
          active: true,
        },
      ],
    },
  },
  {
    path: '/main/visit/operation',
    name: 'visit-operation',
    component: () => import('@/views/main/visit/visit-operation/VisitOperation.vue'),
    meta: {
      pageTitle: 'Visit',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Visits',
          to: '/main/visit/list',
        },
        {
          text: 'Edit Visit',
          active: true,
        },
      ],
    },
  },
  // *==================================================================*
  // *---------------------------- Appointment  ------------------------*
  // *==================================================================*
  {
    path: '/main/appointment/calendar',
    name: 'appointment-calendar',
    component: () => import('@/views/main/appointment/calendar/Calendar.vue'),
    meta: {
      pageTitle: 'Appointments',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Appointments',
          to: '/main/appointment/calendar',
          active: true,
        },
      ],
    },
  },
  // *==================================================================*
  // *---------------------------- Invoice  ----------------------------*
  // *==================================================================*
  {
    path: '/main/invoice/operation',
    name: 'invoice-operation',
    component: () => import('@/views/main/invoice/invoice-operation/InvoiceOperation.vue'),
    meta: {
      pageTitle: 'Invoice',
      breadcrumb: [
        {
          text: 'Main',
        },
        {
          text: 'Invoice',
        },
        {
          text: 'Edit Invoice',
          active: true,
        },
      ],
    },
  },
]
