export default [
  // *==================================================================*
  // *---------------------------- authentication  ----------------------------*
  // *==================================================================*
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/authentication/login/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/authentication/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/authentication/not-authorized/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
]
